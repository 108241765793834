import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"


const IndexPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    
    <Layout location={location} title={siteTitle}>

      <Seo title="TCB Technology" />
      <h1 itemProp="headline">TCB Technology</h1>
      <p>
        Welcome! This site was created by <a href="/about/">Thomas Barrett</a>. 
      </p>

      <h2>Website Development</h2>
      <p>
        Want to improve your website? Looking to integrate with other systems to save your time? 
        Need more functionality in your site? 
        I have decades of experience writing beautiful code that sits quietly, reliably behind the scenes. 
        Constantly working away to save your time and make you money.
      </p>

      <h2>Team Lead</h2>
      <p>
        Need someone to look after your team? Someone effective, communicative and supportive? 
        Someone able to inspire and motivate a group of people into producing quality results? 
        I believe in an inclusive, transparent team effort.
      </p>

      <h2>Hosting</h2>
      <p>
        I have worked with a wide variety of hosting solutions, and created some of my own. 
        Looking for advice on where to host your business? Or how to best host your project? 
        Want something simple and cost effective? Need something global, complex and fast? 
        I can provide you with helpful guidance on your best approach.
      </p>

      <h2>SEO</h2>
      <p>
        In an ever changing world, we have a meta world where machines trawl the internet and build up a giant picture of everything! 
        Experts provide complicated graphs and numbers. 
        I can help you understand the numbers, offer some simple effective advice, and I can build the tools to make your website the darling of the search engines.
        <span dangerouslySetInnerHTML={{ __html: '<!-- Yes, I know this site has not been optimised, but for me it is a playground, and well, you are here, no? -->' }} />
      </p>

      <h2>Updates &amp; Maintenance</h2>
      <p>
        Running your own WordPress website (or websites), and looking for someone to take care of your updates and maintenance? 
        I have manually run more updates than I can remember. And, built large-scale systems to semi-automate updating 100s of sites at the click of a button.
      </p>

      {/* <h2>Documentation &amp; Longevity</h2>
      <p>
        Want someone to understand your business - how your technology and people in real world meet each other?
        I'm able to grasp large scale complex processes and guide others through it.
        Future generations can reap the benefits.
      </p> */}
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`